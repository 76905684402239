import GeneralFilter from '../../../../Model/GeneralFilter'

export default class ParticipantFilter extends GeneralFilter {
  constructor () {
    super()
    this.setInitialValue()
  }

  setInitialValue () {
    this.setFilterInitialValue()
    this.jockey_id = ''
    this.horse_id = ''
    this.race_id = ''
  }
}
