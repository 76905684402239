import api from "@/axios";

export default {
  findAll() {
    return api().get("jockey");
  },
  findOne(id) {
    return api().get(`admin/jockey/${id}`);
  },
  create(data) {
    return api().post("admin/jockey/add", data);
  },
  update(id, data) {
    return api().patch(`admin/jockey/${id}`, data);
  },
  remove(id) {
    return api().delete(`jockey/${id}`);
  },
};
