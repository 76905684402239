<template>
  <div>
    <b-card>
      <ValidationObserver ref="jockey" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="jockey.jockey.translations[0].name" :name="$t('jockeys.nameAr')" :label="$t('jockeys.nameAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="jockey.jockey.translations[1].name" :name="$t('jockeys.nameEn')" :label="$t('jockeys.nameEn')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <participants :jockeyId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import jockeyServices from '../services/jockeys'
import Jockey from '../models/Jockey'
import Participants from '../../../events/participants/views/Participants.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Participants },
  data () {
    return {
      jockey: new Jockey()
    }
  },
  methods: {
    async create () {
      jockeyServices.create({
        translations: this.jockey.jockey.translations
      }).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'contributor.jockeys' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      jockeyServices.update(this.id, {
        translations: this.jockey.jockey.translations
      }).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'contributor.jockeys' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      jockeyServices.findOne(this.id).then(response => {
        this.jockey.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
