<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('participants.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/participant'"
        :reloadData="reloadData"
        :createBtnLabel="$t('participants.create')"
        createRouteName="events.participantCreate"
        :excelFields="excelFields"
        excelName="participants"
        :downloadSampleStatus="true"
        downloadSampleUrl="participant/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="participant/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import ParticipantFilter from '../models/ParticipantFilter'
import searchMixin from '../../../../Mixins/searchMixin'

export default {
  mixins: [searchMixin],
  data () {
    return {
      filter: new ParticipantFilter(),
      fields: [
        this.$t('global.selected'),
        { key: 'id', label: this.$t('table.id'), sortable: true },
        {
          key: 'number',
          label: this.$t('participants.number'),
          sortable: true
        },
        {
          key: 'odd_float',
          label: this.$t('participants.odd'),
          sortable: true
        },
        {
          key: 'last_finishing_positions',
          label: this.$t('participants.lastFinishingPositions'),
          sortable: true
        },
        {
          key: 'end_position',
          label: this.$t('participants.endPosition'),
          sortable: true
        },
        {
          key: 'end_time',
          label: this.$t('participants.endTime'),
          sortable: true
        },
        { key: 'jockey.name', label: this.$t('jockeys.item'), sortable: true },
        { key: 'horse.name', label: this.$t('horses.item'), sortable: true },
        { key: 'race.name', label: this.$t('races.item'), sortable: true },
        {
          key: 'created_at',
          label: this.$t('table.createdAt'),
          type: 'dateTime',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('table.actions'),
          type: 'actions',
          actions: [
            {
              text: this.$t('global.view'),
              icon: 'fas fa-eye',
              color: 'info',
              ifNavigate: true,
              routeName: 'events.participantView',
              routeParams: ['id']
            },
            {
              text: this.$t('global.update'),
              icon: 'fas fa-pen',
              color: 'primary',
              ifNavigate: true,
              routeName: 'events.participantUpdate',
              routeParams: ['id']
            },
            {
              text: this.$t('global.delete'),
              icon: 'fas fa-trash-alt',
              color: 'danger',
              showAlert: true,
              actionHeader: this.$t('global.delete'),
              titleHeader: this.$t('participants.item'),
              textContent: 'name',
              url: 'admin/participant',
              type: 'delete'
            }
          ]
        }
      ],
      excelFields: {
        [this.$t('table.id')]: 'id',
        [this.$t('participants.number')]: 'number',
        [this.$t('participants.odd')]: 'odd',
        [this.$t('participants.lastFinishingPositions')]:
          'last_finishing_positions',
        [this.$t('participants.endPosition')]: 'end_position',
        [this.$t('participants.endTime')]: 'end_time',
        [this.$t('jockeys.item')]: 'jockeyName',
        [this.$t('horses.item')]: 'horseName',
        [this.$t('races.item')]: 'raceName',
        [this.$t('table.createdAt')]: 'created_at'
      }
    }
  },
  props: {
    jockeyId: { default: '' },
    horseId: { default: '' },
    raceId: { default: '' }
  },
  mounted () {
    core.index()
  },
  created () {
    this.filter.jockey_id = this.jockeyId
    this.filter.horse_id = this.horseId
    this.filter.race_id = this.raceId
  }
}
</script>
